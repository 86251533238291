<template>
  <v-container>

    <router-link to="/noticias">
      <v-btn color="primary" small>
        <v-icon class="pr-2">mdi-arrow-left</v-icon>
        Volver a Lista de noticias
      </v-btn>
    </router-link>

    <v-row v-if="!isLoading" class="mb-2">

      <v-container>

        <v-row>
          <v-col>
            <h1 class="mt-4">{{article.title}}</h1>
            <p>{{formattedDate}}</p>
          </v-col>
        </v-row>

        <v-row class="mt-0 mb-3">
          <v-col cols="12">
            <v-card class="titleUnderline" min-height="12px" elevation="1" />
          </v-col>
        </v-row>

        <v-row class="mb-2">
          <v-col>
              <v-img :src="imgSrc" contain max-height="500px"></v-img>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <div v-html="article.sanitizedHtml"></div>
          </v-col>
        </v-row>

      </v-container>
      
    </v-row>

    <LoadingIndicator v-else />

  </v-container>
</template>

<script>
import LoadingIndicator from '@/components/LoadingIndicator'
import {getLongMonthName} from "@/libs/dateHelpers"

export default {
  name: "ViewArticle",
  components: {
    LoadingIndicator,
  },
  data:() => {
    return {
      //state
      isLoading: true,
      //data
      article: {
        title: null,
        slug: null,
        sanitizedHtml: null,
        date: null,
        imgURL: null,
      }
    }
  },
  computed: {
    imgSrc() {
      return this.article.imgURL ? `${process.env.VUE_APP_APIURL}/uploads/${this.article.imgURL}` : ''
    },
    formattedDate: function() {
      if(!(this.article.date instanceof Date)) return ''
      return `${this.article.date.getDate()} de ${getLongMonthName(this.article.date)}`
    },
  },
  methods: {
    async loadArticle() {
      try {

        this.isLoading = true

        if(this.$route.params.slug) {
          const resp = await this.axios.get(`${process.env.VUE_APP_APIURL}/article/slug/${this.$route.params.slug}`)
          Object.assign(this.article, resp.data.data, { date: new Date(resp.data.data.date)})
        }

      } catch (e) {
        console.error(e)
      } finally {
        this.isLoading = false
      }
    },
  },
  mounted() {
    this.loadArticle()
  }
}
</script>

<style lang="scss" scoped>

.titleUnderline {
  background-color: #013A6B;
  background-image: -webkit-linear-gradient(30deg, rgb(243, 115, 53) 25%, #f5af19 10%);
  border-radius: 3px 3px 70px 3px;
}

</style>